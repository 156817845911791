import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import "./App.css"
import Admin from './Admin';

const root = ReactDOM.createRoot(document.getElementById('onceadev'));
root.render(
  <BrowserRouter basename='/'>
    <Routes>
      <Route exact path='/' element={<App />} />
      <Route exact path='/admin' element={<Admin />} />
    </Routes>
  </BrowserRouter>
);

