import React, { useState } from 'react';
import { JaaSMeeting } from '@jitsi/react-sdk';

const Admin = () => {
  const fixedRoomName = 'NTAtestroom001';
  const [username, setUsername] = useState('');
  const [jwtToken, setJwtToken] = useState('');
  const [showMeeting, setShowMeeting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMeetingEnded, setIsMeetingEnded] = useState(false);

  const joinMeeting = () => {
    setIsLoading(true);
    setShowMeeting(true);
  };

  const handleMeetingEnd = () => {
    setIsMeetingEnded(true);
    setIsLoading(false); // Ensure loading is set to false when the meeting ends
    setShowMeeting(false);

    // Reload the page after 5 seconds
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  const handleMeetingReady = () => {
    setIsLoading(false);
  };

  return (
    <div className='parent'>
      {!showMeeting && !isMeetingEnded && (
        <div className='login-cover'>
          <div className='login-input-div'>
              <img src='https://netaspirants.com/wp-content/uploads/2021/02/IMG-20201221-WA0009.jpg' className='logo' alt='Logo'/>
              <br/>
              <label>Enter your username:</label>
              <input
                  type="text"
                  placeholder="Username"
                  onChange={(e) => setUsername(e.target.value)}
              />
              <br />
              <label>Enter the JWT token:</label>
              <input
                  type="text"
                  placeholder="JWT Token"
                  onChange={(e) => setJwtToken(e.target.value)}
              />
              <br />
              <button onClick={joinMeeting}>Join Meeting</button>
          </div>
        </div>
      )}
      {isLoading && <div className="load-meeting">Loading meeting...</div>}
      {showMeeting && (
        <div className='meeting-div'>
          <JaaSMeeting
            appId="vpaas-magic-cookie-819e941040db4177bac2e61a0b616860"
            roomName={fixedRoomName}
            jwt={jwtToken}
            configOverwrite={{
              disableThirdPartyRequests: true,
              disableLocalVideoFlip: true,
              backgroundAlpha: 0.5,
              fileRecordingsEnabled: true,
              liveStreamingEnabled: true,
            }}
            getIFrameRef={(iframeRef) => {
              iframeRef.style.width = '100%';
              iframeRef.style.height = '100%';
              iframeRef.style.position = 'fixed';
              iframeRef.style.top = '0';
              iframeRef.style.left = '0';
              iframeRef.style.zIndex = '9999';
            }}
            interfaceConfigOverwrite={{
              VIDEO_LAYOUT_FIT: 'nocrop',
              MOBILE_APP_PROMO: false,
              TILE_VIEW_MAX_COLUMNS: 4,
            }}
            userInfo={{
              displayName: username,
            }}
            onApiReady={(externalApi) => {
              // Attach custom event listeners to the Jitsi Meet External API
              externalApi.executeCommand('toggleFullScreen');
              externalApi.addEventListeners({
                'participantLeft': handleMeetingEnd,
                'readyToClose': handleMeetingEnd,
                'videoConferenceJoined': handleMeetingReady,
              });
            }}
          />
        </div>
      )}
      {!showMeeting && isMeetingEnded && (
        <div className='end-meeting'>
          Meeting Ended
        </div>
      )}
    </div>
  );
};

export default Admin;
